import { Component } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-card-header',
  standalone: true,
  imports: [],
  templateUrl: './card-header.component.html',
  styleUrl: './card-header.component.scss'
})
export class CardHeaderComponent {
  public hostName: string = '';
  constructor(
    private fireStoreService: FirestoreService,
  ) {
    this.hostName = String(
      this.fireStoreService.publicData?.hostName
    ).toUpperCase();
  }
}
