// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-fixed-size {
  width: 300px; /* Set your desired card width */
  height: 355px; /* Set your desired card height */
  display: flex;
  flex-direction: column;
}

.card-fixed-size .p-1 {
  height: 300px; /* Adjust this to control image container height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-fixed-size img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Your existing styles */
.fas.fa-image {
  color: black;
}

.fas.fa-cloud-upload {
  color: black;
}

/* Scroll up Container Styles */
.scroll-up-container {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1080;
}

.z-2000 {
  z-index: 2000;
}

footer.fixed-bottom {
  pointer-events: none; /* Prevents the footer from blocking clicks */
}

footer.fixed-bottom button {
  pointer-events: auto; /* Allows the button to be clickable */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
