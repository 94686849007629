import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { ModalService } from 'src/app/services/modal.service';
import { IFileInfo } from '../file-selector/file-selector.component';
import { CardHeaderComponent } from 'src/app/components/card-header/card-header.component';

@Component({
  selector: 'app-image-drop',
  templateUrl: './image-drop.component.html',
  styleUrls: ['./image-drop.component.scss'],
})
export class ImageDropComponent {
  @ViewChild('modalContainer', { read: ViewContainerRef })
  modalContainerRef?: ViewContainerRef;
  public hostName: string = '';
  public accessCode: string = '';
  private fileInfoList: IFileInfo[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fireStoreService: FirestoreService,
    private modalService: ModalService
  ) {
    this.hostName = String(
      this.fireStoreService.publicData?.hostName
    ).toUpperCase();
  }

  public ngOnInit(): void {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (accessCode) {
      this.accessCode = accessCode;
    }
  }

  public handleFilesChanged(files: any[]) {
    this.fileInfoList = files;
  }
  public setGalleryUrl() {
    // Handle successful confirmation
    this.router.navigate([this.accessCode, 'gallery']);
  }

  public submit() {
    this.modalService
    .openSendMediaModal(
      this.modalContainerRef!,
      this.accessCode,
      this.fileInfoList 
    ).pipe(take(1))
    .subscribe({
      next: () => {
        // Handle successful confirmation
        if (this.router.url === `/${this.accessCode}/gallery`) {
          // If already on the gallery route, reload the page
          window.location.reload();
        } else {
          this.router.navigate([this.accessCode, 'gallery']);
        }
      },
      error: (error) => {
        alert('An error occured, please try again');
        console.log(error);
      },
    });
  }
}
