import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImageGalleryComponent } from './components/gallery/gallery.component';
import { ImageDropComponent } from './components/image-drop/image-drop.component';
import { accessCodeGuard } from './guards/access-code.guard';


const routes: Routes = [
  {
    path: ':accessCode/drop',
    component: ImageDropComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: ':accessCode/gallery',
    component: ImageGalleryComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
