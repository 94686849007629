// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card-header-logo {
  width: 62px;
  height: auto;
}

#card-header-description {
  position: relative;
  top: 0px;
  text-align: center;
  font-family: "Abel", system-ui;
  font-size: 24px;
  color: #202020;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
}

#card-header-hostname {
  font-family: "Abel", system-ui;
  font-size: 48px;
  color: #202020;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
}

.card-header {
  box-sizing: border-box;
  height: 143px;
  border-radius: 16px;
  border-width: 3px;
  border-color: #8c8c8c;
  background-color: #ffffff;
}

.height-75px {
  height: 75px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
